import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export function useGetDrawerWidth() {
  // this should also have state for collapsed vs not collapsed
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  if (isSmallBreakpoint) {
    return '0px';
  }

  return '17.5rem';
}
