import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { useSiteBandwidthUsage } from 'api/siteMetrics';
import { useParams } from 'react-router-dom';
import ReportingNoData from 'component/svg/chart/no-data.svg?react';
import LoadingContainer from '../../partial/LoadingContainer';
import { useTranslation } from 'react-i18next';
import { getTickFormatForDuration } from 'utils/reporting';
import { tooltip } from 'theme/custom/tooltip';
import { formatUsage } from 'utils/usage';

function CardMetric({
  color,
  label,
  value,
}: {
  readonly color: string;
  readonly value: number;
  readonly label: string;
}) {
  return (
    <Card color="metrics">
      <CardContent>
        <Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4" cy="4" r="4" fill={color} />
            </svg>
            <Typography variant="body1">{label}</Typography>
          </Stack>
          <Typography variant="h4">{value}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
export function SiteActivityCard() {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const [duration, setDuration] = React.useState<string>('24h');
  const { isLoading, data, refetch, isFetched } = useSiteBandwidthUsage({
    id: siteId,
    duration,
  });
  const { t } = useTranslation();
  const timeframes = [
    {
      label: t('last_30_minutes'),
      value: '30m',
    },
    {
      label: t('last_6_hours'),
      value: '6h',
    },
    {
      label: t('last_12_hours'),
      value: '12h',
    },
    {
      label: t('last_24_hours'),
      value: '24h',
    },
    {
      label: t('last_72_hours'),
      value: '72h',
    },
    {
      label: t('last_7_days'),
      value: '7d',
    },
    {
      label: t('last_30_days'),
      value: '30d',
    },
  ];

  const lineData =
    data?.data?.data?.viewer.zones[0].series
      .map(datum => {
        return {
          y: datum.sum.edgeResponseBytes,
          x: datum.dimensions.ts,
        };
      })
      .sort((a, b) => new Date(b.x).getTime() - new Date(a.x).getTime()) ?? [];
  const totalData = lineData.reduce<number>((prev, curr) => prev + curr.y, 0);
  const hasData = !(!lineData || lineData.length === 0);

  const renderChart = () => {
    if (hasData) {
      return (
        <ResponsiveLine
          data={[
            {
              id: 'Data Transfer',
              data: lineData,
            },
          ]}
          // TODO - get this color from theme palette
          colors={['#095bb4']}
          animate={false}
          margin={{ top: 30, right: 30, bottom: 30, left: 70 }}
          xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: true }}
          yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
          yFormat={val => formatUsage(Number(val) / 1000)}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: getTickFormatForDuration(duration),
            tickSize: 0,
            tickPadding: 5,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: val => formatUsage(val / 1000),
          }}
          enableGridX={false}
          curve={'linear'}
          enablePoints={false}
          useMesh
          enableSlices="x"
          xFormat="time:%Y-%m-%d"
          sliceTooltip={({ slice }) => {
            return (
              <Box sx={tooltip}>
                {slice.points.map(point => (
                  <Box key={point.id}>
                    <Typography variant="caption">{point.data.xFormatted}</Typography>
                    <Box>
                      <strong>{point.data.yFormatted}</strong> {point.serieId}
                    </Box>
                  </Box>
                ))}
              </Box>
            );
          }}
          {...(lineData.length === 1
            ? {
                enablePoints: true,
                pointSize: 10,
                enableGridX: true,
              }
            : null)}
        />
      );
    }
    return (
      <Box>
        <ReportingNoData />
        <Typography variant="h3">{t('no_data_to_display')}</Typography>
        <Typography>{t('no_data_to_display_description')}</Typography>
      </Box>
    );
  };

  // if (!hasData) {
  //   return (
  //     <Card sx={{ minHeight: '300px' }}>
  //       <CardContent>{'TODO - temp has no data, get from Josip'}</CardContent>
  //     </Card>
  //   );
  // }

  if (isLoading) {
    return (
      <Card sx={{ minHeight: '300px' }}>
        <CardContent>{'TODO - temp loading card, get from Josip'}</CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        action={
          <TextField
            id="select-timeframe"
            select
            value={duration}
            variant="outlined"
            onChange={e => {
              setDuration(e.target.value);
            }}
          >
            {timeframes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        }
        title="Site Activity"
      />
      <CardContent>
        <Box
          sx={theme => ({
            display: 'grid',
            gridColumnGap: '16px',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            [theme.breakpoints.down('sm')]: {
              gridRowGap: '16px',
              gridTemplateColumns: '1fr',
            },
          })}
        >
          {/* TODO - we will get these colors from the palette */}
          <CardMetric color="#F27C05" label={t('total_requests')} value={5454} />
          <CardMetric color="#64BF36" label={t('served_by_edge')} value={32} />
          <CardMetric color="#E63A0F" label={t('served_by_origin')} value={534} />
          <CardMetric color="#22A6D3" label={t('waf_events')} value={8756} />
        </Box>

        <Box>{isLoading ? <LoadingContainer /> : renderChart()}</Box>
      </CardContent>
    </Card>
  );
}
