import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton } from '@mui/material';
import { ReactNode, useId, useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface DropdownAction {
  icon: ReactNode;
  label: string;
  onClick: () => void;
}

interface DropdownMenuProps {
  readonly actions: DropdownAction[];
  readonly label?: string;
}

export const DropdownMenu = ({ actions, label }: DropdownMenuProps) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const buttonId = useId();
  const menuId = useId();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action: DropdownAction) => () => {
    action.onClick();
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        id={buttonId}
        aria-haspopup="true"
        aria-controls={menuId}
        aria-expanded={anchorEl ? 'true' : 'false'}
        aria-label={label || t('menu')}
      >
        <MoreVert />
      </IconButton>
      {!!anchorEl && (
        <Menu
          id={menuId}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          MenuListProps={{ 'aria-labelledby': buttonId }}
        >
          {actions.map(action => (
            <MenuItem key={action.label} onClick={handleAction(action)}>
              <ListItemIcon>{action.icon}</ListItemIcon>
              <ListItemText>{action.label}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
