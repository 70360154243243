import { useTheme } from '@mui/material';

export default function ServerDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.389 2.5C3.82419 2.5 2.55566 3.76853 2.55566 5.33333V6.33333C2.55566 7.89814 3.82419 9.16667 5.389 9.16667H16.389C17.9538 9.16667 19.2223 7.89814 19.2223 6.33333V5.33333C19.2223 3.76853 17.9538 2.5 16.389 2.5H5.389Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M5.389 10.8333C3.82419 10.8333 2.55566 12.1019 2.55566 13.6667V14.6667C2.55566 16.2315 3.82419 17.5 5.389 17.5H16.389C17.9538 17.5 19.2223 16.2315 19.2223 14.6667V13.6667C19.2223 12.1019 17.9538 10.8333 16.389 10.8333H5.389Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M11.7217 5.83333C11.7217 5.3731 12.0948 5 12.555 5C13.0153 5 13.3883 5.3731 13.3883 5.83333C13.3883 6.29357 13.0153 6.66667 12.555 6.66667C12.0948 6.66667 11.7217 6.29357 11.7217 5.83333Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M15.055 5.83333C15.055 5.3731 15.4281 5 15.8883 5C16.3486 5 16.7217 5.3731 16.7217 5.83333C16.7217 6.29357 16.3486 6.66667 15.8883 6.66667C15.4281 6.66667 15.055 6.29357 15.055 5.83333Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M11.7217 14.1667C11.7217 13.7064 12.0948 13.3333 12.555 13.3333C13.0153 13.3333 13.3883 13.7064 13.3883 14.1667C13.3883 14.6269 13.0153 15 12.555 15C12.0948 15 11.7217 14.6269 11.7217 14.1667Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M15.055 14.1667C15.055 13.7064 15.4281 13.3333 15.8883 13.3333C16.3486 13.3333 16.7217 13.7064 16.7217 14.1667C16.7217 14.6269 16.3486 15 15.8883 15C15.4281 15 15.055 14.6269 15.055 14.1667Z"
        fill={theme.palette.primary.light}
      />
    </svg>
  );
}
