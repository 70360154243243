import { Button } from '@mui/material';
import { FileManagerFile } from 'api/site';
import DirectoryIcon from 'component/new_design/icons/Folder.svg?react';
import FileIcon from 'component/new_design/icons/File.svg?react';

interface FileNameCellProps {
  readonly file: FileManagerFile;
  readonly onClick: () => void;
}

export const FileNameCell = ({ file, onClick }: FileNameCellProps) => {
  const Icon = file.type === 'dir' ? DirectoryIcon : FileIcon;

  return (
    <Button onClick={onClick} variant="text" color="primary" startIcon={<Icon />}>
      {file.name}
    </Button>
  );
};
