import { CardHeader, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export default function CardHeaderWithActions({
  title,
  actions,
}: {
  readonly title: string;
  readonly actions: ReactNode[];
}) {
  return (
    <CardHeader
      title={<Typography variant="h5">{title}</Typography>}
      action={
        <Stack direction="row" gap={1}>
          {...actions}
        </Stack>
      }
    />
  );
}
