import { Box, Container, Typography } from '@mui/material';
import { useGetDrawerWidth } from '../utils/layout';

export default function Page({
  title,
  children,
}: {
  readonly title: string;
  readonly children: React.ReactNode;
}) {
  const drawerWidth = useGetDrawerWidth();

  return (
    <Box
      component="main"
      id="mainContent"
      sx={theme => ({
        width: { sm: `calc(100% - ${drawerWidth})` },
        ml: { sm: `${drawerWidth}` },
        mt: '72px',
      })}
    >
      <Container maxWidth="lg">
        <Box marginBottom={3}>
          <Typography variant="h1" data-testid="domainLabel">
            {title}
          </Typography>
        </Box>
        {children}
      </Container>
    </Box>
  );
}
