import { linkHelper } from 'linkHelper';
import { Route, Routes } from 'react-router-dom';
import Authenticated from '../layout/Authenticated';
import { lazy, Suspense } from 'react';
import { CssBaseline, PaletteMode, ThemeProvider, createTheme } from '@mui/material';
import { onCreateTheme } from '../theme';
import '../theme/base/variables.css';
import '../theme/base/reset.css';
import { COLOR_MODE_KEY } from 'constants';
import SiteDetails from '../view/SiteDetails';

const Dashboard = lazy(() => import('component/new_design/view/Dashboard'));
const Sites = lazy(() => import('component/new_design/view/Sites'));

export default function App() {
  const storedMode = window.localStorage.getItem(COLOR_MODE_KEY) as PaletteMode | null;
  const theme = onCreateTheme(storedMode ?? 'light');

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route
          path={linkHelper.newDesign.dashboard.path}
          element={
            <Authenticated>
              <Suspense fallback={null}>
                <Dashboard />
              </Suspense>
            </Authenticated>
          }
        />
        <Route path={linkHelper.newDesign.sites.path}>
          <Route
            index
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <Sites />
                </Suspense>
              </Authenticated>
            }
          />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.path}
            element={
              <Authenticated>
                <Suspense fallback={null}>
                  <SiteDetails />
                </Suspense>
              </Authenticated>
            }
          />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}
