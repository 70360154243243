import React from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import Close from '../icons/Close.svg?react';

const PREFIX = 'ConfirmationDialog';

const classes = {
  root: `${PREFIX}Root`,
  backdrop: `${PREFIX}Backdrop`,
  paperScrollPaper: `${PREFIX}PaperScrollPaper`,
};

interface Props {
  readonly action: 'delete' | 'confirm';
  readonly id?: string;
  readonly open?: boolean;
  readonly forceLoadingState?: boolean;
  readonly title: React.ReactNode;
  readonly description: React.ReactNode;
  readonly onConfirm?: (arg?: any) => Promise<unknown>;
  readonly confirmDisabled?: boolean;
  readonly confirmText?: string;
  readonly onClose: () => unknown;
  readonly maxWidth?: 'xs' | 'sm' | 'md' | 'lg';
}

export function ConfirmationDialog({
  action,
  id,
  title,
  description,
  onClose,
  onConfirm,
  open = true,
  confirmText = 'Confirm',
  forceLoadingState,
  confirmDisabled = false,
  maxWidth = 'xs',
}: Props) {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const { getSdk } = React.useContext(PostMessageContext);
  const sdk = getSdk();
  const { t } = useTranslation();
  const isLoading = forceLoadingState || submitting;

  const embeddedProps = sdk
    ? {
        className: classes.root,
        classes: {
          paperScrollPaper: classes.paperScrollPaper,
        },
        BackdropProps: {
          classes: { root: classes.backdrop },
        },
      }
    : {};

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      id={id || 'confirm-dialog-modal'}
      open={open}
      onClose={!isLoading ? onClose : undefined}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      TransitionProps={{
        exit: false,
      }}
      hideBackdrop={Boolean(sdk)}
      container={sdk ? window.document.getElementsByTagName('main')[0] : window.document.body}
      style={sdk ? { position: 'absolute' } : {}}
      {...embeddedProps}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" p={1}>
        <Typography variant="h6">{title}</Typography>
        {!isLoading && (
          <IconButton onClick={onClose} color="primary" size="large">
            <Close />
          </IconButton>
        )}
      </Stack>
      <DialogContent>{description}</DialogContent>
      {open ? (
        <DialogActions>
          {onConfirm ? (
            <ProgressiveButton
              data-testid="confirmButton"
              color="primary"
              text={confirmText}
              disabled={confirmDisabled || isLoading}
              onClick={async () => {
                setSubmitting(true);
                try {
                  await onConfirm();
                } finally {
                  setSubmitting(false);
                }
              }}
              isLoading={isLoading}
            />
          ) : null}
          <Button data-testid="closeButton" disabled={isLoading} onClick={onClose} color="primary">
            {t('close')}
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
