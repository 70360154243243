import { useTheme } from '@mui/material';

export default function ReceiptDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.96499 0.833344C8.06183 0.833335 7.33337 0.833329 6.74347 0.881525C6.1361 0.93115 5.60261 1.03599 5.10903 1.28748C4.32502 1.68696 3.6876 2.32437 3.28813 3.10838C3.03664 3.60196 2.9318 4.13545 2.88217 4.74282C2.83397 5.33272 2.83398 6.06118 2.83399 6.96434L2.83399 16.3027C2.83397 16.5907 2.83395 16.8552 2.85138 17.0708C2.86927 17.292 2.91142 17.5738 3.06891 17.8381C3.34718 18.3051 3.83572 18.6071 4.37787 18.6471C4.68469 18.6698 4.9556 18.5815 5.16148 18.4985C5.36214 18.4177 5.59861 18.2994 5.85618 18.1706L5.88313 18.1571C6.42193 17.8877 6.58136 17.8131 6.73707 17.774C6.94674 17.7212 7.16467 17.7098 7.37871 17.7403C7.53766 17.7629 7.70402 17.8204 8.26807 18.032L8.70878 18.1972C9.15884 18.3662 9.499 18.494 9.85727 18.5457C10.1734 18.5914 10.4945 18.5914 10.8107 18.5457C11.169 18.494 11.5091 18.3662 11.9592 18.1972L12.3999 18.032C12.964 17.8204 13.1303 17.7629 13.2893 17.7403C13.5033 17.7098 13.7212 17.7212 13.9309 17.774C14.0866 17.8131 14.246 17.8877 14.7848 18.1571L14.8118 18.1706C15.0693 18.2994 15.3058 18.4177 15.5065 18.4985C15.7124 18.5815 15.9833 18.6698 16.2901 18.6471C16.8323 18.6071 17.3208 18.3051 17.5991 17.8381C17.7566 17.5738 17.7987 17.292 17.8166 17.0708C17.834 16.8551 17.834 16.5907 17.834 16.3027L17.834 6.96432C17.834 6.06121 17.834 5.3327 17.7858 4.74282C17.7362 4.13545 17.6313 3.60196 17.3799 3.10838C16.9804 2.32437 16.343 1.68696 15.559 1.28748C15.0654 1.03599 14.5319 0.931151 13.9245 0.881526C13.3346 0.83333 12.6062 0.833336 11.703 0.833344H8.96499Z"
        fill={theme.palette.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33398 5.83332C6.33398 5.46513 6.63246 5.16666 7.00065 5.16666H13.6673C14.0355 5.16666 14.334 5.46513 14.334 5.83332C14.334 6.20151 14.0355 6.49999 13.6673 6.49999H7.00065C6.63246 6.49999 6.33398 6.20151 6.33398 5.83332ZM6.33398 9.16666C6.33398 8.79847 6.63246 8.49999 7.00065 8.49999H13.6673C14.0355 8.49999 14.334 8.79847 14.334 9.16666C14.334 9.53485 14.0355 9.83332 13.6673 9.83332H7.00065C6.63246 9.83332 6.33398 9.53485 6.33398 9.16666ZM6.33398 12.5C6.33398 12.1318 6.63246 11.8333 7.00065 11.8333H10.334C10.7022 11.8333 11.0007 12.1318 11.0007 12.5C11.0007 12.8682 10.7022 13.1667 10.334 13.1667H7.00065C6.63246 13.1667 6.33398 12.8682 6.33398 12.5Z"
        fill={theme.palette.primary.light}
      />
    </svg>
  );
}
