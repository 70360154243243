import { Skeleton, TableCell, TableRow } from '@mui/material';
import { TableColumnType, TableRowType } from './types';
import { actionsColumn } from './TableRowActions';

interface Props<T extends TableRowType> {
  readonly columns: TableColumnType<T>[];
  readonly hasActionsColumn: boolean;
  readonly perPage: number;
}

export const TableSkeletonRows = <T extends TableRowType>({
  columns,
  perPage,
  hasActionsColumn,
}: Props<T>) => {
  const displayedColumns = hasActionsColumn ? [...columns, actionsColumn] : columns;

  return Array.from({ length: perPage }).map((_, index) => (
    <TableRow key={index} sx={{ opacity: 1 - index / perPage }}>
      {displayedColumns.map(column => (
        <TableCell key={column.label}>
          {column.renderLoader ? column.renderLoader() : <Skeleton />}
        </TableCell>
      ))}
    </TableRow>
  ));
};
