import { useTheme } from '@mui/material';

export default function RocketShipDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.56649 4.74204C5.90375 4.53969 6.20217 4.45703 6.59548 4.45703H10.2435C10.6117 4.45703 10.9102 4.75551 10.9102 5.1237C10.9102 5.49189 10.6117 5.79036 10.2435 5.79036H6.59548C6.44384 5.79036 6.38253 5.80735 6.25249 5.88537C6.25248 5.88537 6.25249 5.88537 6.25249 5.88537L4.74092 6.79231L7.63586 8.033C7.97428 8.17803 8.13105 8.56995 7.98601 8.90837C7.84098 9.24679 7.44906 9.40356 7.11064 9.25852L4.21569 8.01784C4.20796 8.01452 4.20023 8.01121 4.1925 8.0079C4.01926 7.93368 3.84977 7.86107 3.71712 7.78929C3.58646 7.71859 3.37699 7.59138 3.24599 7.35553C3.08942 7.07365 3.06655 6.73668 3.1836 6.43624C3.28153 6.18485 3.4719 6.03051 3.5918 5.9428C3.71353 5.85375 3.87166 5.75891 4.03329 5.66197C4.04049 5.65764 4.04771 5.65332 4.05493 5.64899L5.56649 4.74204ZM15.6543 9.86775C16.0225 9.86775 16.3209 10.1662 16.3209 10.5344L16.3209 14.1825C16.3209 14.5758 16.2383 14.8742 16.0359 15.2115L15.116 16.7447C15.0191 16.9063 14.9242 17.0644 14.8352 17.1862C14.7475 17.3061 14.5931 17.4964 14.3417 17.5944C14.0413 17.7114 13.7043 17.6885 13.4224 17.532C13.1866 17.401 13.0594 17.1915 12.9887 17.0608C12.9169 16.9282 12.8443 16.7587 12.7701 16.5855L11.5194 13.6673C11.3744 13.3289 11.5312 12.937 11.8696 12.7919C12.208 12.6469 12.5999 12.8037 12.745 13.1421L13.9857 16.037L14.8926 14.5255C14.9706 14.3954 14.9876 14.3341 14.9876 14.1825L14.9876 10.5344C14.9876 10.1662 15.2861 9.86775 15.6543 9.86775ZM4.34061 12.9018C4.60096 13.1622 4.60096 13.5843 4.34061 13.8446L3.75135 14.4339C3.491 14.6942 3.06889 14.6942 2.80854 14.4339C2.54819 14.1735 2.54819 13.7514 2.80854 13.4911L3.3978 12.9018C3.65815 12.6415 4.08026 12.6415 4.34061 12.9018ZM7.87614 16.4373C8.13649 16.6977 8.13649 17.1198 7.87614 17.3802L7.28689 17.9694C7.02654 18.2298 6.60443 18.2298 6.34408 17.9694C6.08373 17.7091 6.08373 17.2869 6.34408 17.0266L6.93333 16.4373C7.19368 16.177 7.61579 16.177 7.87614 16.4373Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M18.6011 2.17702C18.2601 1.83599 17.7748 1.73791 17.438 1.69883C17.056 1.65451 16.6178 1.66273 16.1776 1.69619C15.181 1.77193 14.6181 1.81563 13.4345 2.21458C12.6012 2.49547 11.2138 3.23776 10.5177 3.77514C9.52575 4.54093 8.90559 5.33331 7.82806 6.71008L6.98822 7.783C6.56527 8.32272 6.24969 8.72543 6.07302 9.16035C5.69326 10.0952 5.75741 11.1518 6.24749 12.0338C6.47548 12.4442 6.83746 12.8057 7.3226 13.2903L7.48781 13.4555C7.9724 13.9407 8.33397 14.3026 8.74432 14.5306C9.62636 15.0207 10.6829 15.0849 11.6178 14.7051C12.0527 14.5284 12.4554 14.2129 12.9951 13.7899L14.068 12.9501C15.4448 11.8725 16.2372 11.2524 17.003 10.2604C17.5404 9.56433 18.2827 8.17694 18.5635 7.34362C18.9625 6.16002 19.0062 5.5971 19.0819 4.60055C19.1154 4.16031 19.1236 3.72215 19.0793 3.34009C19.0402 3.00328 18.9421 2.51806 18.6011 2.17702Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M6.22677 15.7299C6.55219 15.4044 6.55215 14.8768 6.2267 14.5514C5.90124 14.2259 5.3736 14.226 5.04819 14.5514L2.69131 16.9086C2.36589 17.2341 2.36592 17.7617 2.69138 18.0871C3.01684 18.4125 3.54447 18.4125 3.86989 18.087L6.22677 15.7299Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
}
