import { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Button, Tooltip } from '@mui/material';
import Copy from '../icons/Copy.svg?react';
import { useTranslation } from 'react-i18next';

export const CopyButton = ({ value }: { readonly value: string }) => {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Tooltip
      title="Copied!"
      placement="top"
      open={show}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <Button
        color="primary"
        variant="text"
        onClick={() => {
          copy(value);
          setShow(true);

          setTimeout(() => {
            setShow(false);
          }, 1000);
        }}
        startIcon={<Copy />}
      >
        {t('copy')}
      </Button>
    </Tooltip>
  );
};
