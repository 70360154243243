import { Typography, Card, CardHeader, List } from '@mui/material';
import { SiteDetail } from 'api/site';
import Flag from 'react-flagpack';
import { formatUsage } from 'utils/usage';
import { useTranslation } from 'react-i18next';
import { CopyButton } from './CopyButton';
import { ErrorBoundary } from 'react-error-boundary';
import ListItemRow from './ListItemRow';

interface Props {
  readonly siteDetails?: SiteDetail;
}

export function SiteDetailsCard({ siteDetails }: Props) {
  const { t } = useTranslation();

  if (!siteDetails) {
    return null;
  }

  const location = siteDetails.location?.split(' - ') || ['', ''];

  return (
    <Card>
      <CardHeader title={<Typography variant="h5">{t('site_information')}</Typography>} />
      <List>
        <ListItemRow
          title={t('ftp_address')}
          contentNode={<strong data-testid="ftpIpAddress">{siteDetails.ftp_ip_address}</strong>}
          actionNode={<CopyButton value={siteDetails?.ftp_ip_address ?? ''} />}
        />
        <ListItemRow
          title={t('sftp_username')}
          contentNode={<strong data-testid="sftpUsername">{siteDetails.sftp_username}</strong>}
          actionNode={<CopyButton value={siteDetails?.sftp_username ?? ''} />}
        />
        <ListItemRow
          title={t('cdn_url')}
          contentNode={<strong data-testid="siteRocketUrl">{siteDetails.rocket_url}</strong>}
          actionNode={<CopyButton value={siteDetails?.rocket_url ?? ''} />}
        />
        <ListItemRow
          title={t('location')}
          contentNode={
            <ErrorBoundary
              fallbackRender={() => (
                <strong data-testid="siteLocation">{location[0] ?? 'unknown'}</strong>
              )}
            >
              <strong data-testid="siteLocation">
                <Flag code={location[0] ?? 'US'} size="m" />{' '}
                {typeof location[1] === 'string' ? location[1] : siteDetails.location}
              </strong>
            </ErrorBoundary>
          }
        />
        <ListItemRow
          title={t('disk_usage')}
          contentNode={
            <strong data-testid="siteDiskUsage">{formatUsage(siteDetails.disk_usage)}</strong>
          }
        />
      </List>
    </Card>
  );
}
