import { ListItem, Typography, Stack } from '@mui/material';
import { ReactNode } from 'react';

export default function ListItemRow({
  title,
  contentNode,
  actionNode,
}: {
  readonly title: string;
  readonly contentNode: ReactNode;
  readonly actionNode?: ReactNode;
}) {
  return (
    <ListItem>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Stack gap={0.5}>
          <Typography>{title}</Typography>
          {contentNode}
        </Stack>
        {actionNode ?? null}
      </Stack>
    </ListItem>
  );
}
