import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton } from '@mui/material';
import { TableColumnType, TableRowType, TableRowActionType } from './types';
import { DropdownAction, DropdownMenu } from 'component/new_design/base/DropdownMenu';

export const actionsColumn: NonNullable<TableColumnType<TableRowType>> = {
  label: 'Actions',
  align: 'right',
  renderLoader: () => <Skeleton style={{ width: 40 }} />,
};

interface Props<T extends TableRowType> {
  readonly row: T;
  readonly actions: (TableRowActionType<T> | null)[];
}

export const TableRowActions = <T extends TableRowType>({ row, actions }: Props<T>) => {
  const isActionVisible = (action: Props<T>['actions'][number]): action is TableRowActionType<T> =>
    !!action && !action.hidden?.(row);

  const dropdownActions: DropdownAction[] = actions
    .filter(isActionVisible)
    .map((action: TableRowActionType<T>) => ({
      icon: action.icon,
      label: action.label,
      onClick: () => action.onClick(row),
    }));

  return <DropdownMenu actions={dropdownActions} />;
};
