import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { getMainDomainInfo } from 'utils/site';
import RocketShipSolid from '../icons/RocketShipSolid.svg?react';
import { useTranslation } from 'react-i18next';
import { SiteDetail } from 'api/site';

export default function ReadyToGoLiveCard({ siteDetails }: { readonly siteDetails?: SiteDetail }) {
  // const { sslStatus, showGoLive } = getMainDomainInfo(siteDetails);
  const { t } = useTranslation();

  if (!siteDetails || (siteDetails && siteDetails.production)) {
    //|| showGoLive === 0) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Stack gap={1.5} direction="row">
          <RocketShipSolid />
          <Stack gap={1.5} width="100%">
            <Typography fontWeight="bold">{t('ready_to_go_live')}</Typography>
            <Typography>{t('ready_to_go_live_description')}</Typography>
          </Stack>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {t('get_started')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
