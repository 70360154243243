import { createTheme } from '@mui/material';
import { SpacingOptions } from '@mui/system';
import { cssVar } from 'utils/css';
import { BASE_SPACING } from '../constants';

const theme = createTheme({
  spacing: BASE_SPACING,
});

export const getContrastText = (color: string) => {
  return theme.palette.getContrastText(color);
};

export const getSpacing = theme.spacing;

export const getPrimaryColors = (defaultPrimary: string, defaultPrimaryContrast: string) => {
  const theme = createTheme();
  const sdkPrimary = cssVar('--primary-new');
  let primary = sdkPrimary;
  let primaryContrast = defaultPrimaryContrast;
  if (!sdkPrimary) {
    primary = defaultPrimary;
    primaryContrast = defaultPrimaryContrast;
  } else {
    primaryContrast = getContrastText(primary);
  }

  return {
    primary,
    primaryContrast,
  };
};
