import { FormControl, Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from 'component/base/TextField';
import { useTranslation } from 'react-i18next';
import { useCheckboxStyles } from 'component/styles/checkbox';

// TODO - clean all this up
const PREFIX = 'ClearCacheForm';

const classes = {
  formControl: `${PREFIX}FormControl`,
  typographyLabel: `${PREFIX}TypographyLabel`,
};

const Root = styled('form')({
  [`& .${classes.formControl}`]: {
    marginTop: '1.25rem',
  },
  [`& .${classes.typographyLabel}`]: {
    marginBottom: '0.3125rem',
  },
});

interface ClearCacheFormProps {
  readonly onSubmit: () => Promise<unknown>;
  readonly clearType: string;
}

const ClearCacheForm: FC<ClearCacheFormProps> = ({ onSubmit, clearType }) => {
  const { handleSubmit, setValue, control } = useFormContext();

  const checkboxClasses = useCheckboxStyles();
  const { t } = useTranslation();

  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <Typography>{t('what_do_you_want_to_clear')}</Typography>
      <FormControl className={classes.formControl}>
        <RadioGroup defaultValue="all">
          <Controller
            control={control}
            name="clearType"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                value="all"
                onChange={() => {
                  setValue('clearType', 'all');
                  setValue('paths', undefined);
                }}
                control={
                  <Radio
                    color="primary"
                    checked={clearType === 'all'}
                    className={checkboxClasses.radio}
                  />
                }
                label={
                  <>
                    <Typography className={checkboxClasses.typographyTitle}>
                      {t('clear_all_cache')}
                    </Typography>
                    <Typography variant="body2">{t('cache_purge_warning')}</Typography>
                  </>
                }
                className={checkboxClasses.formControlLabel}
              />
            )}
          />
          <Controller
            control={control}
            name="clearType"
            render={({ field }) => (
              <FormControlLabel
                {...field}
                value="paths"
                onChange={() => setValue('clearType', 'paths')}
                checked={clearType === 'paths'}
                control={<Radio color="primary" className={checkboxClasses.radio} />}
                label={
                  <>
                    <Typography className={checkboxClasses.typographyTitle}>
                      {t('clear_specific_paths')}
                    </Typography>
                    <Typography variant="body2">{t('clear_specific_paths_warning')}</Typography>
                  </>
                }
                className={checkboxClasses.formControlLabel}
              />
            )}
          />
        </RadioGroup>
      </FormControl>
      {clearType === 'paths' && (
        <TextField
          name="paths"
          label={t('enter_paths')}
          fullWidth
          placeholder={t('enter_paths_placeholder')}
          multiline
          color="primary"
          rows="3"
        />
      )}
    </Root>
  );
};

export default ClearCacheForm;
