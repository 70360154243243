import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useDialogProps } from 'component/hooks/useDialogProps';
import Close from 'component/new_design/icons/Close.svg?react';

interface Props {
  readonly open?: boolean;
  readonly isLoading?: boolean;
  readonly title: React.ReactNode;
  readonly description: React.ReactNode;
  readonly onConfirm: () => void | Promise<void>;
  readonly confirmText?: string;
  readonly onClose: () => unknown;
}

export function ConfirmationDialog({
  title,
  description,
  onClose,
  onConfirm,
  open = true,
  confirmText,
  isLoading,
}: Props) {
  const [submitting, setSubmitting] = useState(false);
  const sdkDialogProps = useDialogProps();

  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      id="confirm-dialog-modal"
      open={open}
      onClose={submitting ? undefined : onClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      {...sdkDialogProps}
    >
      <DialogTitle id="confirm-dialog-title">
        {title}
        <IconButton onClick={submitting ? undefined : onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent id="confirm-dialog-description">{description}</DialogContent>
      <DialogActions>
        <LoadingButton
          data-testid="confirmButton"
          color="error"
          variant="contained"
          onClick={async () => {
            setSubmitting(true);
            try {
              await onConfirm();
            } finally {
              setSubmitting(false);
            }
          }}
          loading={isLoading || submitting}
        >
          {confirmText || t('confirm')}
        </LoadingButton>
        <Button data-testid="closeButton" disabled={submitting} onClick={onClose} variant="text">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
