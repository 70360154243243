import { InputAdornment, TextField, debounce } from '@mui/material';
import { PaginatedListState } from 'component/hooks/usePaginatedListState';
import { SearchRounded } from '@mui/icons-material';
import { ChangeEvent } from 'react';

interface Props {
  readonly onSearch: PaginatedListState['setSearch'];
  readonly placeholder: string;
}

export const TableSearch = ({ onSearch, placeholder }: Props) => {
  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    console.log(e);
    return onSearch(e.target.value || '');
  });

  return (
    <TextField
      onChange={handleSearch}
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" disableTypography component="button">
            <SearchRounded />
          </InputAdornment>
        ),
      }}
    />
  );
};
