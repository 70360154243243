import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from '@mui/material';
import { FileManagerFile, useCreateSiteFolder, useUploadSiteFile } from 'api/site';
import { TextField } from 'component/base/TextField';
import { useDialogProps } from 'component/hooks/useDialogProps';
import { useId, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Close from 'component/new_design/icons/Close.svg?react';

interface CreateFileDialogProps {
  readonly type: FileManagerFile['type'];
  readonly path: string;
  readonly siteId: string;
  readonly onClose: (success: boolean) => void;
}

export const CreateFileDialog = ({ type, path, siteId, onClose }: CreateFileDialogProps) => {
  const [submitting, setSubmitting] = useState(false);
  const sdkDialogProps = useDialogProps();

  const createSiteFolder = useCreateSiteFolder(siteId);
  const createSiteFile = useUploadSiteFile(siteId);

  const modalId = useId();
  const titleId = useId();
  const formId = useId();

  const { t } = useTranslation();

  const methods = useForm<{ name: string }>({
    defaultValues: { name: '' },
  });

  const handleSubmit = async (values: { name: string }) => {
    console.log('values', values);
    setSubmitting(true);
    try {
      if (type === 'dir') {
        await createSiteFolder.mutateAsync({ name: values.name, path });
      } else {
        await createSiteFile.mutateAsync({
          filename: values.name,
          path,
          content: '// Write file here',
        });
      }
    } finally {
      setSubmitting(false);
    }
    onClose(true);
  };

  const handleClose = () => {
    if (!submitting) {
      onClose(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      id={modalId}
      open
      onClose={handleClose}
      aria-labelledby={titleId}
      {...sdkDialogProps}
    >
      <DialogTitle id={titleId}>
        {t(type === 'file' ? 'create_file' : 'create_folder')}
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)} id={formId}>
            <TextField
              name="name"
              fullWidth
              label={t('name')}
              placeholder={t(type === 'file' ? 'file_name' : 'folder_name')}
            />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          form={formId}
          loading={submitting}
        >
          {t('Create')}
        </LoadingButton>
        <Button disabled={submitting} onClick={handleClose} variant="text">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
