import { useTheme } from '@mui/material';

export default function LayersDuotone({ selected }: { readonly selected: boolean }) {
  const theme = useTheme();

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.92614 0.633854C11.2254 0.376245 12.4503 0.953434 13.1373 1.94756C12.6653 1.80495 12.1502 1.77195 11.6227 1.87653L5.58772 3.0729C4.0989 3.36825 3.03584 4.68357 3.02594 6.2016L2.98375 12.4521C2.97949 13.1462 3.19225 13.7858 3.55429 14.3096C2.25122 13.9157 1.27811 12.6861 1.28717 11.2094L1.32936 4.95892C1.33926 3.4409 2.40232 2.12558 3.89114 1.83023L9.92614 0.633854Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M12.8727 3.12653C14.1708 2.86916 15.3946 3.44505 16.0819 4.43749C15.6133 4.2979 15.1023 4.26632 14.5793 4.37002L8.54426 5.56639C7.05544 5.86174 5.99238 7.17706 5.98248 8.69509L5.94029 14.9456C5.93602 15.6409 6.14953 16.2815 6.51273 16.8058C5.2037 16.4159 4.22465 15.1832 4.23375 13.7021L4.27594 7.4516C4.28584 5.93357 5.3489 4.61825 6.83772 4.3229L12.8727 3.12653Z"
        fill={theme.palette.primary.light}
      />
      <path
        d="M15.8295 5.61985C17.8136 5.22646 19.624 6.77975 19.6108 8.79172L19.5691 15.0416C19.5592 16.5592 18.4965 17.8754 17.0072 18.1703L10.9723 19.3666C8.98826 19.76 7.17815 18.2069 7.19049 16.1954L7.23268 9.94491C7.24259 8.42689 8.30564 7.11157 9.79446 6.81622L15.8295 5.61985Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
}
