import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Page from 'component/new_design/layout/Page';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { linkHelper } from 'linkHelper';
import { Suspense, useContext, useState } from 'react';
import { SiteLockedPopover } from 'component/partial/SiteLockedPopover';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { isInIframe } from 'utils/iframe';
import HistoryBackButton from '../partial/HistoryBackButton';
import { SiteDetail, useSiteDetails } from 'api/site';
import { SiteSelectorMenu } from 'component/partial/SiteSelectorMenu';
import { isSiteAccess, useGetToken } from 'utils/token';
import { useExternalLink } from 'component/hooks/useExternalLink';
import { LoadingButton } from '@mui/lab';
import CardTab from '../partial/CardTab';
import CurrentMode from '../partial/CurrentMode';
// icons
import PencilEdit from '../icons/PencilEdit.svg?react';
import ClearCache from '../partial/ClearCache';
import ClipboardTextDuotone from '../icons/ClipboardTextDuotone';
import FileSettingsDuotone from '../icons/FileSettingsDuotone';
import FolderSearchDuotone from '../icons/FolderSearchDuotone';
import LayersDuotone from '../icons/LayersDuotone';
import LockCloseDuotone from '../icons/LockCloseDuotone';
import PluginDuotone from '../icons/PluginDuotone';
import ReceiptDuotone from '../icons/ReceiptDuotone';
import RocketShipDuotone from '../icons/RocketShipDuotone';
import ServerDuotone from '../icons/ServerDuotone';
import Wordpress from '../icons/Wp.svg?react';
import { getIsWpSiteControl } from 'utils';
import { SiteDetailsOverview } from '../partial/SiteDetailsOverview';
import { FileManagerTable } from '../partial/tables/file-manager/FileManagerTable';
import { SiteFileContent } from 'component/partial/SiteFileContent';

function TopButtons({ siteId }: { readonly siteId: string }) {
  // TODO - get this from API
  const isSiteLocked = false;
  // TODO - get this from API
  const isLoading = false;
  const { t } = useTranslation();
  const { openExternalLink } = useExternalLink();

  return (
    <Stack direction="row" gap={1}>
      <ClearCache siteId={siteId} />
      <SiteLockedPopover isSiteLocked={isSiteLocked}>
        <LoadingButton
          size="medium"
          onClick={() => {
            openExternalLink({
              iframeUrl: `/manage/${siteId}/wordpress?token=${window.sessionStorage.getItem(
                'user_session'
              )}`,
              url: `/manage/${siteId}/wordpress`,
              relative: true,
            });
          }}
          variant="contained"
          color="secondary"
          startIcon={<Wordpress />}
          fullWidth
          disabled={isSiteLocked}
          loading={isLoading}
        >
          {t('wp_admin')}
        </LoadingButton>
      </SiteLockedPopover>
    </Stack>
  );
}

function FirstRow({
  isSmallBreakpoint,
  siteId,
}: {
  readonly isSmallBreakpoint: boolean;
  readonly siteId: string;
}) {
  if (isSmallBreakpoint) {
    return (
      <Stack justifyContent="space-between" direction="row">
        <HistoryBackButton />
        <TopButtons siteId={siteId} />
      </Stack>
    );
  }
  return <HistoryBackButton />;
}

function SecondRow({
  siteId,
  siteDetails,
  isSmallBreakpoint,
}: {
  readonly siteId: string;
  readonly siteDetails: SiteDetail | undefined;
  readonly isSmallBreakpoint: boolean;
}) {
  const { getSdk } = useContext(PostMessageContext);
  const sdk = getSdk();
  const { t } = useTranslation();
  const [showSiteLabel, setShowSiteLabel] = useState(false);
  const token = useGetToken();

  const domainName = (
    <Stack direction="row" alignItems="baseline" sx={{ flexWrap: 'wrap', gap: 1 }}>
      {sdk || isInIframe() ? (
        <Box>
          <Typography variant="h1" data-testid="domainLabel">
            {siteDetails?.domain ?? ''}
          </Typography>
        </Box>
      ) : (
        // pass wpsitecontrol
        <SiteSelectorMenu siteDetails={siteDetails} isWpSiteControl={false} />
      )}
      {!isSiteAccess(token) && !sdk ? (
        <Button onClick={() => setShowSiteLabel(true)} variant="contained" size="small">
          {siteDetails?.label ?? t('add_label')}
          {siteDetails?.label ? <PencilEdit /> : null}
        </Button>
      ) : null}
    </Stack>
  );

  if (isSmallBreakpoint) {
    return domainName;
  }

  return (
    <Stack justifyContent="space-between" direction="row">
      {domainName}
      <TopButtons siteId={siteId} />
    </Stack>
  );
}

function Navigation({
  siteId,
  isSmallBreakpoint,
}: {
  readonly siteId: string;
  readonly isSmallBreakpoint: boolean;
}) {
  const { t } = useTranslation();

  if (isSmallBreakpoint) {
    return <div>{'>>> this is the mobile menu, building next'}</div>;
  }

  return (
    // TODD - not sure how yet to position all these, once I have built all of them we can determine if we need justifycontent
    <Stack component="nav" gap={2} direction="row">
      <CardTab
        IconComponent={FolderSearchDuotone}
        title={t('overview')}
        to={linkHelper.newDesign.sites.siteDetails.overview.getLink({ siteId })}
      />
      <CardTab
        IconComponent={PluginDuotone}
        title={t('plugin_plural')}
        to={linkHelper.newDesign.sites.siteDetails.plugins.getLink({ siteId })}
      />
      <CardTab
        IconComponent={LayersDuotone}
        title={t('theme_plural')}
        to={linkHelper.newDesign.sites.siteDetails.themes.getLink({ siteId })}
      />
      <CardTab
        IconComponent={FileSettingsDuotone}
        title={t('file_manager')}
        to={linkHelper.newDesign.sites.siteDetails.fileManager.getLink({
          siteId,
        })}
      />
      <CardTab
        IconComponent={ServerDuotone}
        title={t('backup_plural')}
        to={linkHelper.newDesign.sites.siteDetails.backups.getLink({ siteId })}
      />
      <CardTab
        IconComponent={ClipboardTextDuotone}
        title={t('log_plural')}
        to={linkHelper.newDesign.sites.siteDetails.logs.getLink({ siteId })}
      />
      <CardTab
        IconComponent={ReceiptDuotone}
        title={t('reporting')}
        to={linkHelper.newDesign.sites.siteDetails.reporting.getLink({ siteId })}
      />
      <CardTab
        IconComponent={LockCloseDuotone}
        title={t('security')}
        to={linkHelper.newDesign.sites.siteDetails.security.getLink({ siteId })}
      />
      <CardTab
        IconComponent={RocketShipDuotone}
        title={t('advanced')}
        to={linkHelper.newDesign.sites.siteDetails.advanced.getLink({ siteId })}
      />
    </Stack>
  );
}

export default function SiteDetails() {
  const isWpSiteControl = getIsWpSiteControl();
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { data } = useSiteDetails({ id: siteId });
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const siteDetails = data?.data.result;

  return (
    <Page title="Site Details">
      <Stack gap={2}>
        <FirstRow siteId={siteId} isSmallBreakpoint={isSmallBreakpoint} />
        <SecondRow
          siteId={siteId}
          siteDetails={siteDetails}
          isSmallBreakpoint={isSmallBreakpoint}
        />
        <CurrentMode />
        <Navigation siteId={siteId} isSmallBreakpoint={isSmallBreakpoint} />
        <Routes>
          <Route
            path={linkHelper.newDesign.sites.siteDetails.overview.path}
            element={
              <Suspense>
                <SiteDetailsOverview siteDetails={siteDetails} isWpSiteControl={isWpSiteControl} />
              </Suspense>
            }
          />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.plugins.path}
            element={
              <Suspense>
                <div>{'this is the plugins page'}</div>
              </Suspense>
            }
          />
          <Route
            path={linkHelper.newDesign.sites.siteDetails.fileManager.path}
            element={
              <Routes>
                <Route index element={<FileManagerTable siteId={siteId} />} />
                <Route path="create" element={<SiteFileContent siteId={siteId} />} />
                <Route path="view" element={<SiteFileContent siteId={siteId} />} />
              </Routes>
            }
          />
          <Route
            path="*"
            element={
              <Navigate
                to={linkHelper.newDesign.sites.siteDetails.overview.getLink({ siteId })}
                replace
              />
            }
          />
        </Routes>
      </Stack>
    </Page>
  );
}
