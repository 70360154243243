import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Avatar,
  AppBar,
  Drawer,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  useTheme,
  Stack,
  Card,
  Button,
  InputAdornment,
  FilledInput,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  CardContent,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleArrows } from '@fortawesome/pro-regular-svg-icons';
import Dashboard from '../icons/Dashboard.svg?react';
import Monitor from '../icons/Monitor.svg?react';
import Chatting from '../icons/ChatChatting.svg?react';

import { useProfile } from 'api/profile';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cssVar } from 'utils/css';
import { getUsername, isAccountUser, isSiteAccess, useGetToken } from 'utils/token';
import { linkHelper } from 'linkHelper';
import { useAuth0 } from '@auth0/auth0-react';
import { useBillingSso } from 'api/auth';
import SidebarLeftClose from '../icons/SidebarLeftClose.svg?react';
import Chat from '../icons/Chat.svg?react';
import Ticket from '../icons/Ticket.svg?react';
import SearchBig from '../icons/SearchBig.svg?react';
import InformationCircle from '../icons/InformationCircle.svg?react';
import ChevronDown from '../icons/ChevronDown.svg?react';
import NotificationBell from '../icons/NotificationBell.svg?react';
import BurgerMenuThree from '../icons/BurgerMenuThree.svg?react';
import { useGetDrawerWidth } from '../utils/layout';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexShrink: '0',
  justifyContent: 'center',
  padding: '1.5rem 0',
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  flexShrink: '0',
}));

const UserButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  appearance: 'none',
  backgroundColor: 'transparent',
  border: '0',
  borderRadius: '0',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1rem 1.25rem',
  position: 'relative',
  width: '100%',
  '&::before': {
    backgroundColor: cssVar('--color-white'),
    content: '""',
    height: '0.0625rem',
    left: '0',
    position: 'absolute',
    right: '0',
    top: '0',
    opacity: '0.1',
  },
  '&:hover': {
    backgroundColor: 'greys.900',
    color: cssVar('--color-curious-blue'),
  },
  '& > span': {
    alignItems: 'center',
    color: cssVar('--color-white'),
    display: 'flex',
    fontSize: '0.875rem',
    fontWeight: '600',
    '& > *': {
      backgroundColor: cssVar('--color-blue-dianne'),
      color: cssVar('--color-regent-gray'),
      fontSize: '0.75rem',
      fontWeight: '400',
      height: '2rem',
      marginRight: '0.625rem',
      width: '2rem',
    },
  },
  '& > div': {
    backgroundColor: 'greys.900',
    borderRadius: cssVar('--border-radius'),
    color: cssVar('--color-curious-blue'),
    display: 'flex',
    height: '2rem',
    width: '2rem',
    '& > *': {
      margin: 'auto',
    },
  },
}));

const LogoButton = styled(IconButton)(({ theme }) => ({
  margin: '0 auto',
  padding: '0',
  cursor: 'pointer',
  '& img': {
    display: 'block',
    height: 'auto',
    margin: '0',
    maxHeight: '2.25rem',
    maxWidth: '45vw',
    width: 'auto',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

interface Props {
  readonly hasData?: boolean;
  readonly drawerOpen: boolean;
  readonly onDrawerOpenToggle: () => unknown;
}

function UserAvatar({
  firstname,
  lastname,
  size,
}: {
  readonly firstname?: string;
  readonly lastname?: string;
  readonly size: number;
}) {
  return (
    <Avatar
      sx={{
        display: 'inline-flex',
        marginRight: '5px',
        width: `${size}px`,
        height: `${size}px`,
        fontSize: '14px',
      }}
      alt={`${firstname} ${lastname}`}
    >
      {firstname?.charAt(0) ?? ''}
      {lastname?.charAt(0) ?? ''}
    </Avatar>
  );
}

export function Header({ hasData, drawerOpen, onDrawerOpenToggle }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const token = useGetToken();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoading, data } = useProfile();
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const { logout } = useAuth0();
  const { mutateAsync: getBillinglink } = useBillingSso();
  const accountUser = isAccountUser(token);
  let name = '';

  if (data?.data.result?.client?.firstname && data?.data.result?.client?.lastname) {
    name = `${data?.data.result?.client?.firstname} ${data?.data.result?.client?.lastname}`;
  } else {
    name = data?.data.result?.client?.email ?? '';
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const shouldShowMenu = hasData && token && !isSiteAccess(token);
  const drawerWidth = useGetDrawerWidth();

  const drawerLinks = (
    <div>
      <List>
        <ListItemButton
          onClick={() => navigate(linkHelper.dashboard.getLink())}
          selected={pathname.startsWith(linkHelper.newDesign.dashboard.getLink())}
        >
          <Dashboard />
          <ListItemText primary={t('dashboard')} />
        </ListItemButton>
        <ListItemButton
          onClick={() => navigate(linkHelper.sites.getLink())}
          selected={pathname.startsWith(linkHelper.newDesign.sites.getLink())}
        >
          <Monitor />
          <ListItemText primary={t('sites')} />
        </ListItemButton>
        <ListItemButton href="https://support.rocket.net" target="_blank">
          <Chatting />
          <ListItemText primary={t('support')} />
        </ListItemButton>
        <ListItemButton href="https://rocket.net/affiliate-program" target="_blank">
          <FontAwesomeIcon icon={faPeopleArrows} fixedWidth />
          <ListItemText primary={t('affiliates')} />
        </ListItemButton>
      </List>
    </div>
  );

  const userMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      id="user-menu"
      keepMounted
      onClose={handleClose}
      open={open}
    >
      <MenuItem>
        <Typography variant="body2">{getUsername(token)}</Typography>
      </MenuItem>
      {accountUser ? null : (
        <MenuItem
          onClick={async () => {
            const { data } = await getBillinglink({
              destination: 'feature-requests.php',
            });
            window.location.href = data.result?.link ?? '';
          }}
        >
          {t('feature_requests')}
        </MenuItem>
      )}
      {accountUser ? null : (
        <MenuItem
          onClick={async () => {
            const { data } = await getBillinglink({
              destination: 'clientarea.php',
              params: 'action=details',
            });
            window.location.href = data.result?.link ?? '';
          }}
        >
          {t('edit_account_details')}
        </MenuItem>
      )}
      {accountUser ? null : (
        <MenuItem
          onClick={async () => {
            const { data } = await getBillinglink({
              destination: 'index.php',
              params: 'rp=/account/paymentmethods',
            });
            window.location.href = data.result?.link ?? '';
          }}
        >
          {t('payment_methods')}
        </MenuItem>
      )}
      {accountUser ? null : (
        <MenuItem
          onClick={() => {
            handleClose();
            navigate(linkHelper.users.getLink());
          }}
        >
          {t('account_users')}
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          handleClose();
          navigate(linkHelper.sites.templates.getLink());
        }}
      >
        {t('site_templates')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClose();
          navigate(linkHelper.profile.changePassword.getLink());
        }}
      >
        {t('change_password')}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleClose();
          navigate(linkHelper.profile.twoFactorAuthentication.getLink());
        }}
      >
        {t('security_settings')}
      </MenuItem>
      {accountUser ? null : (
        <MenuItem
          onClick={async () => {
            const { data } = await getBillinglink({
              destination: 'clientarea.php',
              params: 'action=emails',
            });
            window.location.href = data.result?.link ?? '';
          }}
        >
          {t('email_history')}
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}/logout`,
            },
          });
        }}
      >
        {t('logout')}
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          width: { sm: `calc(100% - ${drawerWidth})` },
          ml: { sm: `${drawerWidth}` },
          boxShadow: 'none',
          px: 10,
          py: 4,
        }}
      >
        <Stack justifyContent="space-between" spacing={3} direction="row">
          <FilledInput
            size="small"
            fullWidth
            placeholder={t('global_search.placeholder')}
            startAdornment={
              <InputAdornment position="start">
                <SearchBig />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <Stack direction="row" alignItems="center">
                  <InformationCircle />
                  <Typography ml={1}>{t('global_search.search_tips')}</Typography>
                  <Box
                    sx={{
                      lineHeight: 1,
                      backgroundColor: 'white',
                      borderRadius: cssVar('--border-radius-xs'),
                      color: 'greys.300',
                      ml: 3,
                      py: 0.5,
                      px: 1.5,
                      boxShadow:
                        '0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 3px 1px 0px rgba(0, 0, 0, 0.01), 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.09)',
                    }}
                  >
                    /
                  </Box>
                </Stack>
              </InputAdornment>
            }
          />
          <Button
            variant="contained"
            color="header"
            size="large"
            sx={{
              flex: 'none',
              // this probably belongs in the theme
              px: 3,
              py: 2,
            }}
            startIcon={<CircularProgress color="primary" size={18} />}
            endIcon={<ChevronDown />}
          >
            {t('global_search.still_working', {
              value: 39,
            })}
          </Button>
          <IconButton color="header" sx={{ width: '45px' }}>
            <NotificationBell />
          </IconButton>
          <IconButton color="header">
            <Stack spacing={1} direction="row" alignItems="center">
              <UserAvatar
                size={28}
                firstname={data?.data.result?.client?.firstname}
                lastname={data?.data.result?.client?.lastname}
              />
              <BurgerMenuThree />
            </Stack>
          </IconButton>
        </Stack>
      </AppBar>
      {isSmallBreakpoint ? null : (
        <Drawer
          sx={theme => ({
            display: 'block',
            '&.MuiDrawer-docked': {
              display: 'flex',
              flex: '1 1 auto',
            },
            '& .MuiDrawer-paper': {
              backgroundColor: 'greys.900',
              borderRightWidth: '0',
              display: 'flex',
              width: '17.5rem',
            },
          })}
          variant="permanent"
          anchor="left"
          open
        >
          <DrawerHeader>
            <Stack direction="row" justifyContent="space-between" sx={{ width: '100%', mx: 3 }}>
              <LogoButton
                aria-label="logo"
                size="large"
                onClick={() => navigate(linkHelper.dashboard.getLink())}
              >
                <img src="/rocket.svg" alt="Rocket.net" />
              </LogoButton>
              <IconButton size="large" edge="end" sx={{ color: 'greys.400' }}>
                <SidebarLeftClose />
              </IconButton>
            </Stack>
          </DrawerHeader>
          {drawerLinks}
          <DrawerFooter
            sx={{
              marginTop: 'auto',
            }}
          >
            <Box sx={{ padding: 2, textAlign: 'center' }}>
              <Card
                sx={{
                  backgroundColor: cssVar('--color-outer-space-2'),
                  color: 'white',
                  '& .MuiCardContent-root': {
                    padding: '8px',
                  },
                }}
                elevation={0}
              >
                <CardContent>
                  <Stack spacing={1}>
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                      <UserAvatar
                        size={25}
                        firstname={data?.data.result?.client?.firstname}
                        lastname={data?.data.result?.client?.lastname}
                      />
                      <Box>
                        <Typography color="sidebar">{name}</Typography>
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent="space-between">
                      <Button
                        startIcon={<Chat />}
                        variant="contained"
                        color="sidebar"
                        size="small"
                        fullWidth
                      >
                        Chat
                      </Button>
                      <Button
                        startIcon={<Ticket />}
                        onClick={() => {
                          window.open('https://support.rocket.net', '_blank', 'noreferrer');
                        }}
                        variant="contained"
                        color="sidebar"
                        size="small"
                        fullWidth
                      >
                        Ticket
                      </Button>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          </DrawerFooter>
        </Drawer>
      )}
    </>
  );
}
