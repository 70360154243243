import { Box, Card, CardContent, Typography, Stack } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { JSX } from 'react';

export default function CardTab({
  IconComponent,
  title,
  to,
}: {
  readonly IconComponent: (props: { readonly selected: boolean }) => JSX.Element;
  readonly title: string;
  readonly to: string;
}) {
  // TODO - need to figure out the breakpoints of this component
  const { pathname } = useLocation();

  return (
    <Link to={to}>
      <Card sx={{ minWidth: '118px' }}>
        <CardContent sx={{ padding: '12px' }}>
          <Stack gap={2}>
            <IconComponent selected={pathname.startsWith(to)} />
            <Typography>{title}</Typography>
          </Stack>
        </CardContent>
      </Card>
    </Link>
  );
}
