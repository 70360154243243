import { Box, Button, IconButton, Card, Menu, MenuItem, List, Stack } from '@mui/material';
import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChangeDomainDialog } from './ChangeDomainDialog';
import { SiteDetailDomain, AdditionalDomains, AdditionalDomain } from 'api/site';
import { useDeleteDomain } from 'api/domain';
import { ConfirmationDialog } from './ConfirmationDialog';
import EditIcon from '@mui/icons-material/Edit';
import ListItemRow from 'component/new_design/partial/ListItemRow';
import CardHeaderWithActions from 'component/new_design/partial/CardHeaderWithActions';
import Plus from '../icons/Plus.svg?react';
import ThreeDotsMenu from '../icons/ThreeDotsMenu.svg?react';
import PencilEdit from '../icons/PencilEdit.svg?react';
import DeleteDustbin from '../icons/DeleteDustbin.svg?react';

interface Props {
  readonly domain: string;
  readonly domains: (SiteDetailDomain | AdditionalDomains)[] | undefined;
}

function AddtionalDomainRow({
  additionalDomain,
  siteId,
  setDomainToDelete,
}: {
  readonly additionalDomain: AdditionalDomain;
  readonly siteId: string;
  readonly setDomainToDelete: (val: AdditionalDomain) => void;
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    handleClose();
  };
  const handleDelete = () => {
    setDomainToDelete(additionalDomain);
    handleClose();
  };
  const menuId = `additional-domain-menu-${additionalDomain.id}`;
  const buttonId = `additional-domain-button-${additionalDomain.id}`;

  return (
    <ListItemRow
      title={t('alias')}
      contentNode={<strong data-testid="domainListUrl">{additionalDomain.domain}</strong>}
      actionNode={
        <Box display="inline-block" marginLeft={1}>
          <IconButton
            id={buttonId}
            aria-controls={open ? menuId : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            color="primary"
            size="small"
          >
            <ThreeDotsMenu />
          </IconButton>
          <Menu
            id={menuId}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': buttonId,
            }}
          >
            <MenuItem onClick={handleEdit}>
              <Stack direction="row" gap={1} alignItems="center">
                <PencilEdit />
                {t('edit')}
              </Stack>
            </MenuItem>
            <MenuItem onClick={handleDelete}>
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                sx={theme => ({
                  color: theme.palette.reds[500],
                })}
              >
                <DeleteDustbin />
                {t('delete')}
              </Stack>
            </MenuItem>
          </Menu>
        </Box>
      }
    />
  );
}

export function DomainsListCard({ domain, domains }: Props) {
  const { t } = useTranslation();
  const [changeDomain, setChangeDomain] = useState<string | null>(null);
  const [domainToDelete, setDomainToDelete] = useState<AdditionalDomain | null>(null);
  const canChangeDomain = !['onrocket.site', 'wpdns.site'].some(url => domain.endsWith(url));
  const { siteId = '' } = useParams<{ siteId: string }>();
  const siteAdditionalDomains = domains?.find(
    domain => domain && 'additional_domains' in domain
  ) as AdditionalDomains | null;
  const deleteDomain = useDeleteDomain(siteId);

  return (
    <>
      <ConfirmationDialog
        action="delete"
        onClose={() => setDomainToDelete(null)}
        open={Boolean(domainToDelete)}
        onConfirm={async () => {
          await deleteDomain.mutateAsync({ id: domainToDelete?.id ?? 0 });
          setDomainToDelete(null);
        }}
        title={t('delete_domain')}
        description={t('delete_additional_domain', {
          title: domainToDelete?.domain,
        })}
      />
      {changeDomain ? (
        <ChangeDomainDialog siteId={siteId} domain={domain} onClose={() => setChangeDomain(null)} />
      ) : null}
      <Card>
        <CardHeaderWithActions
          title={t('domain_plural')}
          actions={[
            <Button color="primary" variant="text" key="domainInfo">
              {t('domain_information')}
            </Button>,
            <Button color="primary" variant="contained" startIcon={<Plus />} key="addAlias">
              {t('add_alias')}
            </Button>,
          ]}
        />
        <List>
          <ListItemRow
            title={t('primary')}
            contentNode={<strong data-testid="domainListUrl">{domain}</strong>}
            actionNode={
              canChangeDomain ? (
                <Box display="inline-block" marginLeft={1}>
                  <IconButton color="primary" size="small" onClick={() => setChangeDomain(domain)}>
                    <EditIcon />
                  </IconButton>
                </Box>
              ) : null
            }
          />
          {siteAdditionalDomains?.additional_domains?.map(additionalDomain => {
            return (
              <AddtionalDomainRow
                key={additionalDomain.id}
                additionalDomain={additionalDomain}
                setDomainToDelete={domainToDelete => setDomainToDelete(domainToDelete)}
                siteId={siteId}
              />
            );
          })}
        </List>
      </Card>
    </>
  );
}
