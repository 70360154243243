import { useContext } from 'react';
import { Box, Stack } from '@mui/material';
import { SiteDetail } from 'api/site';
// import { SiteDetailsCard } from '../SitesDetailsCard';
// import { DomainsListCard } from '../DomainsListCard';
import { SiteActivityCard } from './SiteActivityCard';
// import { FtpAccounts } from 'component/partial/FtpAccounts';
// import { SiteUserList } from 'component/partial/SiteUserList';
import ReadyToGoLiveCard from './ReadyToGoLiveCard';
import { PostMessageContext } from 'component/postMessage/PostMessageContext';
import { SiteDetailsCard } from './SitesDetailsCard';
import { Domain } from '@mui/icons-material';
import { DomainsListCard } from './DomainsListCard';

interface Props {
  readonly siteDetails?: SiteDetail;
  readonly isWpSiteControl: boolean;
}

export function SiteDetailsOverview({ siteDetails, isWpSiteControl }: Props) {
  const { getSdk } = useContext(PostMessageContext);
  const sdk = getSdk();

  return (
    <Stack gap={2}>
      <ReadyToGoLiveCard siteDetails={siteDetails} />
      <SiteActivityCard />
      <Box
        sx={theme => ({
          display: 'grid',
          gridColumnGap: '16px',
          gridTemplateColumns: '1fr 1fr',
          [theme.breakpoints.down('sm')]: {
            gridRowGap: '16px',
            gridTemplateColumns: '1fr',
          },
        })}
      >
        <SiteDetailsCard siteDetails={siteDetails} />
        <DomainsListCard domains={siteDetails?.domains} domain={siteDetails?.domain ?? ''} />
      </Box>
      {/* 
      <Grid item xs={12} md={6}>
        <SiteDetailsCard siteDetails={siteDetails} />
      </Grid>
      <Grid item xs={12} md={6}>
        <DomainsListCard domains={siteDetails?.domains} domain={siteDetails?.domain ?? ''} />
      </Grid>
      <Grid item xs={sdk || isWpSiteControl ? 12 : 6}>
        <FtpAccounts domain={siteDetails?.domain ?? ''} />
      </Grid>
      {!sdk && !isWpSiteControl && (
        <Grid item xs={6}>
          <SiteUserList siteDetails={siteDetails} />
        </Grid>
      )} */}
    </Stack>
  );
}
