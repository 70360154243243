import { createTheme } from '@mui/material/styles';
import { cssVar, important } from 'utils/css';
import { PaletteMode } from '@mui/material';
import { createLightPalette } from './palettes/light';
import { createDarkPalette } from './palettes/dark';
import { BASE_SPACING } from './constants';
import { getSpacing } from './palettes/utils';

declare module '@mui/material/styles' {
  interface Palette {
    greys: Palette['grey'];
    blues: Palette['grey'];
    greens: Palette['grey'];
    oranges: Palette['grey'];
    reds: Palette['grey'];
    // TODO - need to figure this out, might be able to use a different option
    sidebar?: Palette['primary'];
    header?: Palette['primary'];
  }

  interface PaletteOptions {
    greys?: PaletteOptions['grey'];
    blues?: PaletteOptions['grey'];
    greens?: PaletteOptions['grey'];
    oranges?: PaletteOptions['grey'];
    // TODO - need to figure this out, might be able to use a different option
    sidebar?: PaletteOptions['primary'];
    header?: PaletteOptions['primary'];
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    header: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    sidebar: true;
    header: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    sidebar: true;
    header: true;
  }
}

declare module '@mui/material/Card' {
  interface CardPropsColorOverrides {
    metrics: true;
  }
}

export const onCreateTheme = (mode: PaletteMode) => {
  const isLightMode = mode === 'light';
  const palette = isLightMode ? createLightPalette() : createDarkPalette();

  return createTheme({
    palette: {
      ...palette,
      sidebar: {
        main: '#FFFFFF',
      },
      header: {
        main: '#FFFFFF',
      },

      divider: palette.greys[50],
    },
    spacing: BASE_SPACING,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1300,
      },
    },
    components: {
      MuiAvatar: {
        styleOverrides: {
          circular: {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.main,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: palette.common.white,
          },
        },
      },
      MuiCard: {
        defaultProps: {
          elevation: 0,
        },
        variants: [
          {
            props: { color: 'metrics' },
            style: {
              backgroundColor: palette.greys[50],
              color: palette.greys[500],
            },
          },
        ],
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            minHeight: '68px',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            borderRadius: cssVar('--border-radius'),
            minWidth: '230px',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: getSpacing(4),
            paddingTop: important(getSpacing(4)),
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: getSpacing(4),
            justifyContent: 'flex-start',
            borderTop: `1px solid ${palette.divider}`,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          '@global': {
            body: {
              backgroundColor: cssVar('--body-background'),
              color: palette.greys[700],
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.6',
              minWidth: '20rem',
              '& > .SnackbarContent-root': {
                '#notistack-snackbar': {
                  paddingLeft: '60px',
                },
              },
            },
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            backgroundColor: palette.greys[100],
          },
          root: {
            color: `${palette.primary.main} !important`,
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          startIcon: {
            marginRight: 0,
          },
          root: {
            whiteSpace: 'nowrap',
            display: 'inline-flex',
            alignItems: 'center',
            gap: '4px',
          },
          contained: {
            borderRadius: cssVar('--border-radius'),
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          },
          outlined: {
            borderRadius: cssVar('--border-radius'),
          },
        },
        variants: [
          {
            props: { color: 'header', variant: 'contained' },
            style: {
              backgroundColor: palette.greys[50],
              color: palette.greys[900],
              '&:hover': {
                border: `1px solid ${palette.primary.main}`,
              },
            },
          },
          {
            props: { color: 'sidebar', variant: 'contained' },
            style: {
              // this is does not change from light to dark theme
              backgroundColor: cssVar('--color-grey-900'),
              color: '#FFFFFF',
              '&:hover': {
                border: `1px solid ${palette.primary.main}`,
              },
            },
          },
          {
            props: { color: 'secondary', variant: 'contained' },
            style: {
              backgroundColor: palette.common.white,
              color: palette.primary.main,
              '&:hover': {
                border: `1px solid ${palette.primary.main}`,
              },
            },
          },
          {
            props: { size: 'small' },
            style: {
              height: '24px',
              minWidth: '24px',
              padding: '4px 8px',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '16px',
            },
          },
          {
            props: { size: 'medium' },
            style: {
              height: '36px',
              minWidth: '36px',
              padding: '8px 12px',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '16px',
            },
          },
          {
            props: { size: 'large' },
            style: {
              height: '44px',
              minWidth: '44px',
              padding: '12px 16px',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
            },
          },
          {
            props: { variant: 'text' },
            style: {
              height: 'auto',
              minWidth: 'auto',
              padding: 0,
            },
          },
        ],
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: cssVar('--color-grey-900'),
          },
          root: {
            '@media (min-width: 56.25rem)': {
              display: 'none',
            },
            '& [class*="MuiListItem-root"]': {
              paddingLeft: '1rem',
              paddingRight: '1rem',
            },
            '& [class*="MuiTypography-root"]': {
              fontWeight: 600,
            },
          },
          paperAnchorTop: {
            top: '3.75rem',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: '100px',
            fontSize: '0.875rem',
            padding: '0.5625rem',
            transition: 'none',
            '& svg': {
              height: '1.125rem',
              width: '1.125rem',
            },
          },
        },
        variants: [
          {
            props: { color: 'header' },
            style: {
              color: palette.greys[500],
              backgroundColor: palette.greys[50],
            },
          },
        ],
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            marginRight: '12px',
            marginLeft: '12px',
            borderRadius: cssVar('--border-radius'),
            backgroundColor: 'transparent',
            color: palette.greys[400],
            transition: 'none',
            marginBottom: '5px',
            '&.MuiListItemButton-root.Mui-selected': {
              color: palette.primary.contrastText,
              backgroundColor: palette.primary.main,
              '&:hover': {
                color: palette.primary.contrastText,
                backgroundColor: palette.primary.main,
              },
              '& > svg': {
                color: palette.primary.contrastText,
              },
            },
            '& svg:first-of-type': {
              marginRight: '0.75rem',
            },
            '&:hover': {
              color: palette.greys[400],
              backgroundColor: palette.greys[800],
            },

            '& > svg': {
              color: palette.greys[400],
            },
            '& > svg:first-of-type': {
              marginRight: '0.3125rem',
            },
            '& .flag': {
              display: 'inline-flex',
              marginRight: '0.3125rem',
              marginTop: '-0.125rem',
              verticalAlign: 'middle',
              '& img': {
                display: 'block',
              },
            },
          },
          gutters: {
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: cssVar('--border-radius'),
            backgroundColor: palette.greys[50],
            color: palette.greys[400],
            height: '44px',
            padding: '12px 10px',
            '&:hover': {
              backgroundColor: palette.greys[50],
            },
          },
          focused: {
            backgroundColor: `${palette.greys[50]} !important`,
          },
          underline: {
            '&:before': {
              display: 'none !important',
            },
            '&:after': {
              display: 'none !important',
            },
          },
          input: ({ theme }) => ({
            padding: '0px',
            '::placeholder': {
              color: theme.palette.greys[400],
              opacity: 1,
            },
          }),
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: getSpacing(4),
            gap: getSpacing(6),
            minHeight: '36px',
            borderWidth: '0 0 1px 0',
            borderStyle: 'solid',
            borderColor: palette.divider,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: cssVar('--border-radius-l'),
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundColor: 'transparent',
            color: theme.palette.greys[400],
            border: '0',
            borderRadius: '0',
            height: 'auto',
            padding: '0',
            margin: 0,
          }),
          positionStart: {
            marginRight: getSpacing(2),
          },
          positionEnd: {
            marginLeft: getSpacing(2),
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            paddingLeft: important(getSpacing(2.5)),
            paddingRight: important(getSpacing(2.5)),
          },
          sizeSmall: {
            paddingLeft: important(getSpacing(3)),
            paddingRight: important(getSpacing(3)),
          },
          input: {
            paddingTop: important(getSpacing(3)),
            paddingBottom: important(getSpacing(3)),
          },
          inputSizeSmall: {
            paddingTop: important(getSpacing(2.5)),
            paddingBottom: important(getSpacing(2.5)),
          },
        },
      },
    },
    // need to change these colors
    typography: {
      fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
      fontSize: 14,
      h1: {
        color: palette.greys[900],
        fontSize: 32,
        fontWeight: 700,
        lineHeight: '1.3',
      },
      h2: {
        color: palette.greys[900],
        fontSize: 22,
        fontWeight: 700,
        lineHeight: '1.3',
      },
      h3: {
        color: palette.greys[900],
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '1.3',
      },
      h4: {
        color: palette.greys[900],
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '32px',
      },
      h5: {
        color: palette.greys[900],
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '1.3',
      },
      h6: {
        color: palette.greys[900],
        fontSize: 18,
        fontWeight: 700,
        lineHeight: '1.3',
      },
      body1: {
        fontSize: 16,
        lineHeight: '24px',
      },
      body2: {
        fontSize: 14,
        lineHeight: '20px',
      },
      caption: {
        fontSize: 12,
        lineHeight: '16px',
      },
      button: {
        textTransform: 'none',
      },
    },
  });
};
