import {
  Card,
  CardContent,
  Table as MuiTable,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { PaginatedListState } from 'component/hooks/usePaginatedListState';
import { TableColumnType, TableRowType, TableRowActionType } from './types';
import { TableRowActions } from './TableRowActions';
import PaginationControls from '../PaginationControls';
import { TableSkeletonRows } from './TableSkeletonRows';
import { TableHead } from './TableHead';
import { TableSearch } from './TableSearch';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

interface Props<T extends TableRowType> {
  readonly title: ReactNode;
  readonly description?: ReactNode;
  readonly label: string;
  readonly isLoading: boolean;
  readonly data: T[];
  readonly totalRowCount: number;
  readonly state: PaginatedListState;
  readonly rowActions?: (TableRowActionType<T> | null)[];
  readonly columns: (TableColumnType<T> | null)[];
  readonly enableSearch?: boolean;
  readonly enableNoResultsMessage?: boolean;
  readonly enablePagination?: boolean;
  readonly tableBar?: ReactNode;
  readonly children?: ReactNode;
  readonly searchPlaceholder?: string;
  readonly rowIdKey?: keyof T;
}

export const Table = <T extends TableRowType>({
  title,
  description,
  label,
  isLoading,
  data,
  totalRowCount,
  state,
  rowActions,
  columns,
  enableSearch = false,
  enableNoResultsMessage = true,
  enablePagination = true,
  tableBar,
  children,
  searchPlaceholder = '',
  rowIdKey = 'id',
}: Props<T>) => {
  const { t } = useTranslation();

  const { params, setSort, setSearch, setPage, setPerPage } = state;

  const nonEmptyColumns = columns.filter(Boolean) as TableColumnType<T>[];

  const showActionsColumn = !!rowActions?.length;
  const showNoResultsMessage =
    !data?.length && !isLoading && !params.search && enableNoResultsMessage;

  const getCellContent = (row: T, column: NonNullable<TableColumnType<T>>) => {
    if (column.renderValue) {
      return column.renderValue(row);
    } else if (column.key) {
      return String(row[column.key]);
    } else {
      return '-';
    }
  };

  return (
    <Card>
      <Stack direction="row" justifyContent="space-between" alignItems="center" p={4} gap={10}>
        <Stack gap={1}>
          <Typography variant="body1" fontWeight={600}>
            {title}
          </Typography>
          {description ? (
            <Typography variant="caption" color="greys.500" component="span">
              {description}
            </Typography>
          ) : null}
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          {enableSearch ? (
            <TableSearch onSearch={setSearch} placeholder={searchPlaceholder} />
          ) : null}
          {children}
        </Stack>
      </Stack>

      <CardContent>
        {tableBar}

        <TableContainer>
          <MuiTable aria-label={label}>
            <TableHead
              columns={nonEmptyColumns}
              hasActionsColumn={showActionsColumn}
              sortBy={params.sortBy}
              sortDirection={params.sortDirection}
              onSort={setSort}
            />
            <TableBody>
              {isLoading ? (
                <TableSkeletonRows
                  columns={nonEmptyColumns}
                  perPage={params.perPage}
                  hasActionsColumn={showActionsColumn}
                />
              ) : (
                data.map(row => (
                  <TableRow key={row[rowIdKey]}>
                    {nonEmptyColumns.map(column => (
                      <TableCell key={column.label}>{getCellContent(row, column)}</TableCell>
                    ))}
                    {showActionsColumn ? (
                      <TableCell>
                        <TableRowActions row={row} actions={rowActions} />
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))
              )}
            </TableBody>
          </MuiTable>

          {showNoResultsMessage ? <Typography>{t('no_results_found')}</Typography> : null}

          {enablePagination ? (
            <PaginationControls
              totalRowCount={totalRowCount}
              perPageOptions={params.perPageOptions}
              perPage={params.perPage}
              onPageChange={setPage}
              onPerPageChange={setPerPage}
            />
          ) : null}
        </TableContainer>
      </CardContent>
    </Card>
  );
};
