import { cssVar } from 'utils/css';
import { getPrimaryColors } from './utils';
import { Palette } from '@mui/material';

export const createLightPalette = () => {
  const { primary, primaryContrast } = getPrimaryColors(cssVar('--color-blue-500'), '#FFFFFF');

  return {
    mode: 'light',
    primary: {
      // TODO - need to figure if we are going to allow dark mode for sdk colors
      main: primary,
      contrastText: primaryContrast,
    },
    secondary: {
      // TODO - not sure how we handle a passed in color from the sdk, im thinking we lighten it if light mode and darken it if dark mode
      main: cssVar('--color-blue-100'),
      contrastText: cssVar('--color-blue-800'),
    },
    text: {
      primary: cssVar('--color-grey-900'),
      secondary: cssVar('--color-grey-500'),
    },
    background: {
      default: cssVar('--color-grey-50'),
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    greys: {
      50: cssVar('--color-grey-50'),
      100: cssVar('--color-grey-100'),
      200: cssVar('--color-grey-200'),
      300: cssVar('--color-grey-300'),
      400: cssVar('--color-grey-400'),
      500: cssVar('--color-grey-500'),
      600: cssVar('--color-grey-600'),
      700: cssVar('--color-grey-700'),
      800: cssVar('--color-grey-800'),
      900: cssVar('--color-grey-900'),
    },
    blues: {
      50: cssVar('--color-blue-50'),
      100: cssVar('--color-blue-100'),
      200: cssVar('--color-blue-200'),
      300: cssVar('--color-blue-300'),
      400: cssVar('--color-blue-400'),
      500: cssVar('--color-blue-500'),
      600: cssVar('--color-blue-600'),
      700: cssVar('--color-blue-700'),
      800: cssVar('--color-blue-800'),
      900: cssVar('--color-blue-900'),
    },
    greens: {
      50: cssVar('--color-green-50'),
      100: cssVar('--color-green-100'),
      200: cssVar('--color-green-200'),
      300: cssVar('--color-green-300'),
      400: cssVar('--color-green-400'),
      500: cssVar('--color-green-500'),
      600: cssVar('--color-green-600'),
      700: cssVar('--color-green-700'),
      800: cssVar('--color-green-800'),
      900: cssVar('--color-green-900'),
    },
    oranges: {
      50: cssVar('--color-orange-50'),
      100: cssVar('--color-orange-100'),
      200: cssVar('--color-orange-200'),
      300: cssVar('--color-orange-300'),
      400: cssVar('--color-orange-400'),
      500: cssVar('--color-orange-500'),
      600: cssVar('--color-orange-600'),
      700: cssVar('--color-orange-700'),
      800: cssVar('--color-orange-800'),
      900: cssVar('--color-orange-900'),
    },
    reds: {
      50: cssVar('--color-red-50'),
      100: cssVar('--color-red-100'),
      200: cssVar('--color-red-200'),
      300: cssVar('--color-red-300'),
      400: cssVar('--color-red-400'),
      500: cssVar('--color-red-500'),
      600: cssVar('--color-red-600'),
      700: cssVar('--color-red-700'),
      800: cssVar('--color-red-800'),
      900: cssVar('--color-red-900'),
    },
  } as Palette;
};
